import React, {FC, useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {AuthUserContext, YoDataSource} from '@yakoffice/yakoffice-firebase';
import {CustomSpinner} from "@yakoffice/custom-spinner";
import {ViewHeader} from '@yakoffice/view-header';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import {useItem, useItemSearchParameters} from "../use-item";
import {DataObjectComponent} from "../data-object";
import {DataObjectBase, ObjectProperty} from "../../model/data";
import {CreateDataSourceRoute, CreateItemRoute} from '../../routes/PlayersRoutes';
import {YoGameEnvironmentRoles} from '../../config/yo-game-environment-roles';
import {SearchItemInputGroup} from '../search-item-input-group/search-item-input-group';
import {useCopyItemController} from '../use-copy-item-controller/use-copy-item-controller';
import {DataSourceSelector} from '../data-source-selector/data-source-selector';
import styles from './dataSource.module.sass'
import {CopyToItemInputGroup} from '../copy-to-item-input-group/copy-to-item-input-group';
import {useDataSource} from '../use-data-sources';
import {NoAccess} from "@yakoffice/shared-components";
import {SaveAllControllerContext, useSaveAllController} from '../use-save-all-controller';
import {Button} from "react-bootstrap";
import {useDeleteUserController} from '../privacy-framework/use-delete-user-controller';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {DeletionRequests} from '../privacy-framework/deletionRequests';
import {useClearPlayerCacheController} from '../use-clear-player-cache-controller/use-clear-player-cache-contorller';


export const ReRenderItemContext = React.createContext({} as () => void)

export const DataSource : FC = () => {

  const authUser                                = useContext(AuthUserContext);
  const navigate                                = useNavigate();
  const {projectId, gameEnvironmentId, itemId}  = useParams() as {projectId: string, gameEnvironmentId: string, itemId: string};
  const dataSource                              = useDataSource();
  const [searchParameters, setSearchParameters] = useState<useItemSearchParameters>({itemId: ""})
  const itemResult                              = useItem(searchParameters)
  const copyItemController                      = useCopyItemController();
  const saveAllController                       = useSaveAllController();
  const deleteUserController                    = useDeleteUserController();
  const clearPlayerCacheController              = useClearPlayerCacheController();

  useEffect(() => {
    if (itemId)
      setSearchParameters({itemId: itemId});
  }, [itemId, setSearchParameters])

  const handleDataSourceChange = (dataSource : YoDataSource) => {
    setSearchParameters({itemId: ""});
    navigate(CreateDataSourceRoute(projectId, gameEnvironmentId.toLowerCase(), dataSource.id))
  }

  const handleGameEnvironmentChange = (gameEnvironmentId: string) => {
    setSearchParameters({itemId: ""})
    navigate(CreateDataSourceRoute(projectId, gameEnvironmentId.toLowerCase(), dataSource?.id))
  }

  const handleSearchItem = (itemId: string) => {
    setSearchParameters({itemId: itemId});
    navigate(CreateItemRoute(projectId, gameEnvironmentId, dataSource?.id, itemId))
  }

  const handleCopyItem = (geId: string, copyToItemId: string) => {
    dataSource && copyItemController.handleCopyItem(projectId, dataSource.id, gameEnvironmentId, searchParameters.itemId, geId, copyToItemId);
  }

  const handleSaveAll = async () => {
    await saveAllController.handleSaveAllUpdates()
    reRenderItem()
  }

  const reRenderItem = () => {
    setSearchParameters({itemId: searchParameters.itemId});
  }

  const handleDeleteUser = async () => {
    await deleteUserController.handleStartUserDeletion();
  }

  const handleClearPlayerCache = async () => {
    await clearPlayerCacheController.handleClearPlayerCache();
  }

  return (authUser?.hasGameEnvironmentRoleClaim(projectId, gameEnvironmentId, YoGameEnvironmentRoles.ViewPlayers)
          ?
          <div id="datasource">
            <Tabs
              defaultActiveKey="playerProfile"
              id="playerActions"
              className="mb-3 mt-2"
            >
              <Tab eventKey="playerProfile" title="Players">
                <div className="pt-4">
                  <Container>
                    <ViewHeader
                      title={`Search`}
                      customAction={<DataSourceSelector handleDataSourceChange={handleDataSourceChange} />}
                    />
                    <Row className={styles.searchForm}>
                      <Col sm={12} md={6} className="mb-2">
                        <SearchItemInputGroup handleGameEnvironmentChange={handleGameEnvironmentChange} handleSearchItem={handleSearchItem} />
                      </Col>
                      <Col sm={12} md={6}  className="mb-2">
                        {(itemResult.isLoaded && itemResult.item && authUser?.hasGameEnvironmentRoleClaim(projectId, gameEnvironmentId, YoGameEnvironmentRoles.CopyPlayers)) && <CopyToItemInputGroup handleCopyItem={handleCopyItem} />}
                      </Col>
                      <Col md={2}/>
                    </Row>
                    <Row className="justify-content-end mb-2">
                      {(authUser?.hasGameEnvironmentRoleClaim(projectId, gameEnvironmentId, YoGameEnvironmentRoles.EditPlayers) || authUser?.hasGameEnvironmentRoleClaim(projectId, gameEnvironmentId, YoGameEnvironmentRoles.ServicePlayers)) &&
                       <Col sm="auto">
                         {(itemResult.isLoaded && itemResult.item) &&
                          <Button variant="success" onClick={() => handleSaveAll()} disabled={!saveAllController.hasUnsavedUpdatedProperties()} data-testid="btnSaveAll">
                            <i className="fas fa-save icon-with-text"/>
                            <span className="text"> Save All</span>
                          </Button>
                         }
                       </Col>
                      }
                      {authUser?.hasGameEnvironmentRoleClaim(projectId, gameEnvironmentId, YoGameEnvironmentRoles.EditPlayers) && gameEnvironmentId.toLowerCase() !== "production" && projectId.toLowerCase() !== "ppf" &&
                       <Col sm="auto">
                         {(itemResult.isLoaded && itemResult.item) &&
                          <Button variant="warning" onClick={() => handleClearPlayerCache()} data-testid="btnClearPlayerCache">
                            <i className="fas fa-tools icon-with-text"/>
                            <span className="text"> Clear Player Cache</span>
                          </Button>
                         }
                       </Col>
                      }
                      {authUser?.hasGameEnvironmentRoleClaim(projectId, gameEnvironmentId, YoGameEnvironmentRoles.EditPlayers) &&
                       <Col sm="auto">
                         {(itemResult.isLoaded && itemResult.item) &&
                          <Button variant="danger" onClick={() => handleDeleteUser()} data-testid="btnDeletePlayerAll">
                            <i className="fas fa-trash icon-with-text"/>
                            <span className="text"> Delete User</span>
                          </Button>
                         }
                       </Col>
                      }

                    </Row>
                    <Row className="mb-1">
                      <Col>
                        {itemResult.isLoading &&
                         <CustomSpinner spinnerText={`Searching for ${dataSource?.name}...`} />
                        }
                        {(itemResult.isLoaded && !itemResult.item) &&
                         <Alert variant="warning" className="text-center" data-testid="alertNotFound">
                           <strong>Warning:</strong> {`${dataSource?.name} with id (${searchParameters.itemId}) not found`}
                         </Alert>
                        }
                        {(itemResult.isLoaded && itemResult.item) &&
                         <ReRenderItemContext.Provider value={reRenderItem}>
                           <SaveAllControllerContext.Provider value={saveAllController}>
                           </SaveAllControllerContext.Provider>
                           <DataObjectComponent
                             route={[]}
                             dataObject={itemResult.item as ObjectProperty}
                           />
                         </ReRenderItemContext.Provider>
                        }
                      </Col>
                    </Row>
                  </Container>
                </div>
              </Tab>
              {authUser?.hasGameEnvironmentRoleClaim(projectId, gameEnvironmentId, YoGameEnvironmentRoles.EditPlayers) && <Tab eventKey="deletionFramework" title="Deletion Requests">
                <DeletionRequests handleGameEnvironmentChange={handleGameEnvironmentChange} />
              </Tab>}
            </Tabs>
          </div>
          : <NoAccess />
  )
}
