import {YoGameEnvironmentRoles} from '../../config/yo-game-environment-roles';
import {YoAuthUser} from '@yakoffice/yakoffice-firebase';
import {DataObjectBase} from '../../model/data';

export enum DataPropertyEditorLevel {
  Administrator,
  CustomerService,
  None
}


export const IsPropertyEditable = (property: DataObjectBase, projectId: string, gameEnvironmentId: string, authUser: YoAuthUser | null): boolean => {

  const authUserCanEditProperty = DetermineDataPropertyEditorLevelsFromGameEnvironmentRoles(projectId, gameEnvironmentId, authUser) <= property.editorLevel;

  return authUserCanEditProperty && property.isEditable;
}

const DetermineDataPropertyEditorLevelsFromGameEnvironmentRoles = (projectId: string, gameEnvironmentId: string, authUser: YoAuthUser | null): DataPropertyEditorLevel => {

  const authUserRoles = authUser?.getGameEnvironmentRoles(projectId, gameEnvironmentId);

  if(authUserRoles == undefined)
    return DataPropertyEditorLevel.None;

  if(authUserRoles.find(role => role == YoGameEnvironmentRoles.EditPlayers))
    return DataPropertyEditorLevel.Administrator;

  else if(authUserRoles.find(role => role == YoGameEnvironmentRoles.ServicePlayers))
    return DataPropertyEditorLevel.CustomerService;

  else
    return DataPropertyEditorLevel.None;
}
