import React, {FC, useContext} from 'react';
import { DataComponentPropTypes } from '../data-component-prop-types';
import {DataObjectBase, ObjectProperty} from '../../../../model/data';
import { YoTable } from '@yakoffice/shared-components';
import { useGameEnvironmentCssClass } from '@yakoffice/use-game-environment-css-class';
import { DataObjectComponentFactory } from './data-object-component-factory';
import { Col, Row } from 'react-bootstrap';
import styles from './array-property-component.module.sass'
import {IsPropertyEditable} from '../../data-object-edit-controller';
import {AuthUserContext} from '@yakoffice/yakoffice-firebase';
import {useParams} from 'react-router-dom';

export const ArrayPropertyComponent : FC<DataComponentPropTypes<ObjectProperty>> = ({route, dataObject, readonly}) => {
  const cssClass = useGameEnvironmentCssClass()

  const authUser = useContext(AuthUserContext);
  const {projectId, gameEnvironmentId}  = useParams() as {projectId: string, gameEnvironmentId: string, itemId: string};
  const isPropertyReadonly = (arrayItemProperty: DataObjectBase):boolean => {
    return !IsPropertyEditable(arrayItemProperty, projectId, gameEnvironmentId, authUser);
  }

  return dataObject.value.length === 0
    ? <span>No Items</span>
    : <Row>
        <Col>
          <YoTable className={`${styles.table}`} responsive variant={cssClass} data-testid={`table${dataObject.id}`}>
            <thead className="table-dark">
            <tr>
              <th key="itemKey">Item</th>
              {(dataObject.value[0] as ObjectProperty)?.value.map(property => <th key={property.id}>{property.label}</th>)}
            </tr>
            </thead>
            <tbody>
            {dataObject.value.map((arrayItem, index) => (
              <tr key={`${arrayItem.id}-indx${index}`} data-testid={`tr${arrayItem.id}`}>
                <td data-testid={`tdLabel${arrayItem.id}`}>{arrayItem.label}</td>
                {(arrayItem as ObjectProperty).value.map(arrayItemProperty =>
                  <td key={arrayItemProperty.id}>
                    {
                      <DataObjectComponentFactory key={[...route, dataObject, arrayItem].map(o => o.id).join("-") + arrayItemProperty.id}
                                                  route={[...route, dataObject, arrayItem]}
                                                  dataObject={arrayItemProperty}
                                                  readonly={isPropertyReadonly(arrayItemProperty)}
                                                  displayLabel={false}/>
                    }
                  </td>
                )}
              </tr>
            ))}
            </tbody>
          </YoTable>
        </Col>
      </Row>
}
