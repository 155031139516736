import React, {FC, useContext} from "react";
import {DataObjectBase, DataObjectType, ObjectProperty} from '../../model/data';
import {DataObjectComponentFactory} from "./components";
import {DataComponentPropTypes} from "./components/data-component-prop-types";
import Card from "react-bootstrap/Card";
import Tab from "react-bootstrap/esm/Tab";
import {DataObjectCard} from './components/data-object-card';
import {ItemTabs} from '../item-tabs';
import {AuthUserContext} from '@yakoffice/yakoffice-firebase';
import {useParams} from 'react-router-dom';
import {IsPropertyEditable} from './data-object-edit-controller';


const complexPropertyTypes = [DataObjectType.object, DataObjectType.array, DataObjectType.postable];

export const  DataObjectComponent : FC<DataComponentPropTypes<ObjectProperty>> = ({route, dataObject}) => {

  const authUser = useContext(AuthUserContext);
  const {projectId, gameEnvironmentId}  = useParams() as {projectId: string, gameEnvironmentId: string, itemId: string};

  const simpleProperties = dataObject.value.filter(p => !complexPropertyTypes.includes(p.type));
  const complexProperties = dataObject.value.filter(p => complexPropertyTypes.includes(p.type));
  const buildFactory = (property: DataObjectBase) => {

    return (
      <div className="mb-3" key={route.map(o => o.id).join("-") + property.id}>
        <DataObjectComponentFactory
          route={[...route, dataObject]}
          dataObject={property}
          readonly={!IsPropertyEditable(property, projectId, gameEnvironmentId, authUser)}
          displayLabel={true}/>
      </div>
    )
  }

  const simplePropertiesCard = (
    <DataObjectCard>
      <Card.Body>
        {simpleProperties.map(property => buildFactory(property))}
      </Card.Body>
    </DataObjectCard>
  )

  return (
    complexProperties.length === 0
      ? simplePropertiesCard
      : <ItemTabs defaultActiveKey={simpleProperties.length === 0 && complexProperties.length > 0 ? dataObject.id + complexProperties[0].id : dataObject.id}>
        {simpleProperties.length > 0 &&
        <Tab key={dataObject.id}
             eventKey={dataObject.id}
             title={<span className={``}>{dataObject.label}</span>}
             data-testid={`tab${dataObject.id}`}>
          {simplePropertiesCard}
        </Tab>
        }
        {complexProperties
          .filter(property => property.type !== DataObjectType.postable || IsPropertyEditable(property, projectId, gameEnvironmentId, authUser))
          .map(property => (
            <Tab key={dataObject.id + property.id}
                 eventKey={dataObject.id + property.id}
                 title={<span className={``}>{property.label}</span>}
                 className="mt-2"
                 data-testid={`tab${dataObject.id + property.id}`}>
              {buildFactory(property)}
            </Tab>
          ))}
      </ItemTabs>
  )
}
