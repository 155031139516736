
export enum DataObjectType{
  array = "array", // Note:  "array" can only have an array of "objects"
  boolean = "boolean",
  double = "double",
  decimal = "decimal",
  image = "image",
  int = "int",
  object = "object",  // Note:  "object" can have a array of "objects" and "properties" (i.e. superset of "array")
  postable = "postable",
  string = "string",
  timestamp = "timestamp",
  urlLink = "url-link"
}

export interface DataObjectBase {
  type: DataObjectType,
  id: string,
  label: string,
  isEditable: boolean,
  editorLevel: number,
  isRequired: boolean
}

export interface DataProperty<T> extends DataObjectBase{
  value: T
  enumValues?: {[key: string]: string}
}

export interface ObjectProperty extends DataProperty<DataObjectBase[]>{}
export interface BooleanProperty extends DataProperty<boolean | undefined>{}
export interface DoubleProperty extends DataProperty<number | undefined>{}
export interface DecimalProperty extends DataProperty<number | undefined>{}
export interface StringProperty extends DataProperty<string | undefined>{
  maxLength?: number
}
export interface ImageProperty extends DataProperty<string | undefined>{
  maxLength?: number
}
export interface UrlLinkProperty extends DataProperty<string | undefined>{
  title: string
}
export interface TimestampProperty extends DataProperty<number | undefined>{}
export interface IntProperty extends DataProperty<number | undefined>{
  minValue?: number
  maxValue?: number
}
export interface PostableObject extends DataProperty<DataObjectBase[]>{}



